import React from "react";
import classes from "./HomePage.module.css";
import g5 from "../../assets/assets/5g.png";
import missionPing from "../../assets/assets/mission.png";
import whatWeDo from "../../assets/assets/whatwedo.png";

// import { Button } from "@material-ui/core";

function HomePage(props) {
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    return (
        <div className={classes.HomePage}>
            <div className={classes.Banner}>
                <div className={classes.Intro}>
                    <h1>
                        AERI <p>5G</p>
                    </h1>
                    <h3>5G Network Planning System</h3>
                </div>
                <img className={classes.g5} src={g5} alt="" />
            </div>
            <div className={classes.Banner} style={{ justifyContent: "flex-start" }}>
                <div className={[classes.Intro, classes.Alternate].join(" ")}>
                    <h4>Our Mission</h4>
                    <p>
                        Enable Telecommunication Companies to rapidly plan 5G RAN Architectures and Optimum Small Cell placements
                        in the real world using Drones
                    </p>
                </div>
                <img className={classes.g5} src={missionPing} alt="" />
            </div>
            <div className={classes.Banner}>
                <div className={classes.Intro}>
                    <h4>What We Do</h4>
                    <ol>
                        <li>
                            Using Drones to develop high resolution maps of areas and determining the optimum positions for the
                            installationof 5G Antennae.
                        </li>
                        <li>Providing accurate network plans with cabling and fronthaul layouts</li>
                    </ol>
                </div>
                <img className={classes.g5} src={whatWeDo} alt="" />
            </div>
        </div>
    );
}

export default HomePage;
