import * as actionTypes from "./actionTypes";
import Axios from "axios";

export const resultFetchStart = () => {
    return {
        type: actionTypes.RESULT_FETCH_START,
    };
};

export const resultFetchSuccess = (result) => {
    return {
        type: actionTypes.RESULT_FETCH_SUCCESS,
        result: result,
    };
};

export const resultFetchFail = (error) => {
    return {
        type: actionTypes.RESULT_FETCH_FAIL,
        error: error,
    };
};

export const resultFetch = (markers, inc) => {
    return (dispatch) => {
        dispatch(resultFetchStart());
        const token = localStorage.getItem("token");
        let url = "https://5g.uavswarm.tech/api/result";
        Axios.get(url, {
            headers: {
                "x-auth-header": token,
            },
        })
            .then((response) => {
                dispatch(resultFetchSuccess(response.data.element));
            })
            .catch((err) => {
                dispatch(resultFetchFail(err.response));
            });
    };
};
