import React, { useEffect } from "react";
import "./App.css";
import HomePage from "./containers/HomePage/HomePage";
import { Switch, Route, Redirect } from "react-router";
import Layout from "./hoc/Layout/Layout";
import Login from "./containers/Login/Login";
import SignUp from "./containers/SignUp/SignUp";
import DeployDrone from "./containers/DeployDrone/DeployDrone";
import Result from "./containers/Result/Result";
import { connect } from "react-redux";
import Logout from "./containers/Logout/Logout";
import * as action from "./store/actions/index";
import Missions from "./containers/Missions/Missions";
import Prototype from "./containers/Prototype/Prototype";

const App = (props) => {
    useEffect(() => {
        props.onTryAutoSignUp();
    }, []);
    let route = (
        <Switch>
            <Route path="/signup" exact component={SignUp} />
            <Route path="/login" exact component={Login} />
            <Route path="/prototype" exact component={Prototype} />
            <Route path="/" component={HomePage} />;
            <Redirect to="/" />
        </Switch>
    );
    if (props.isAuthenticated) {
        route = (
            <Switch>
                <Route path="/deploy" exact component={DeployDrone} />
                <Route path="/result" exact component={Result} />
                <Route path="/missions" exact component={Missions} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/prototype" exact component={Prototype} />
                <Route path="/" component={HomePage} />
                <Redirect to="/" />
            </Switch>
        );
    }
    return (
        <div className="App">
            <Layout>{route}</Layout>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};

const mapDispatchToProp = (dispatch) => {
    return {
        onTryAutoSignUp: () => dispatch(action.authCheckState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(App);
