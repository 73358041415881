import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Logo from "../Logo/Logo";
import DrawerToggle from "./SideDrawer/DrawerToggle/DrawerToggle";
import classes from "./Toolbar.module.css";

const Toolbar = (props) => {
    const goToHome = () => {
        props.history.push("/");
    };
    let routes = (
        <nav className={classes.NavigationLinks}>
            <NavLink activeClassName={classes.active} to="/" exact>
                Home
            </NavLink>

            <NavLink activeClassName={classes.active} to="/prototype" exact>
                Prototype
            </NavLink>
            <NavLink activeClassName={classes.active} to="/signup" exact>
                SignUp
            </NavLink>
            <NavLink activeClassName={classes.active} to="/login" exact>
                Login
            </NavLink>
        </nav>
    );
    if (props.isAuthenticated) {
        routes = (
            <nav className={classes.NavigationLinks}>
                <NavLink activeClassName={classes.active} to="/" exact>
                    Home
                </NavLink>

                <NavLink activeClassName={classes.active} to="/deploy" exact>
                    Deploy
                </NavLink>
                <NavLink activeClassName={classes.active} to="/missions" exact>
                    Missions
                </NavLink>
                <NavLink activeClassName={classes.active} to="/result" exact>
                    Result
                </NavLink>
                <NavLink activeClassName={classes.active} to="/prototype" exact>
                    Prototype
                </NavLink>
                <NavLink activeClassName={classes.active} to="/logout" exact>
                    Logout
                </NavLink>
            </nav>
        );
    }
    return (
        <div className={classes.Toolbar}>
            <DrawerToggle onClick={props.drawerToggle} />
            <Logo onClick={goToHome} />
            {routes}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};

export default connect(mapStateToProps, null)(withRouter(Toolbar));
