import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    result: [],
    resultFetchError: null,
    resultFetchLoading: false,
};

const resultFetchStart = (state, action) => {
    return updateObject(state, {
        resultFetchError: null,
        resultFetchLoading: true,
    });
};

const resultFetchSuccess = (state, action) => {
    return updateObject(state, {
        resultFetchLoading: false,
        resultFetchError: null,
        result: action.result,
    });
};

const resultFetchFail = (state, action) => {
    return updateObject(state, {
        resultFetchError: action.error,
        resultFetchLoading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESULT_FETCH_START:
            return resultFetchStart(state, action);
        case actionTypes.RESULT_FETCH_SUCCESS:
            return resultFetchSuccess(state, action);
        case actionTypes.RESULT_FETCH_FAIL:
            return resultFetchFail(state, action);
        default:
            return state;
    }
};

export default reducer;
