import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import classes from "./Missions.module.css";
import GeneralMap from "../../components/GeneralMap/GenealMap";
import * as action from "../../store/actions/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";

const Missions = (props) => {
    let [state, setState] = useState({
        deleteLoading: false,
        deleteId: null,
    });
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    useEffect(() => {
        props.fetchResults();
    }, [state.deleteLoading]);

    useEffect(() => {
        deleteHandler();
    }, [state.deleteLoading]);

    const resultStyles = {
        backgroundColor: "rgba(255, 255, 255, 0.46)",
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.25)",
    };
    const deleteHandler = () => {
        axios
            .post(
                `https://5g.uavswarm.tech/api/result/delete/${state.deleteId}`,
                {},
                {
                    headers: {
                        "x-auth-header": props.token,
                    },
                },
            )
            .then((response) => {
                console.log(response.data);
                setState({
                    ...state,
                    deleteLoading: false,
                    deleteId: null,
                });
                return;
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const deleteMission = (id) => {
        window.scrollTo(0, 0);

        setState({
            ...state,
            deleteLoading: true,
            deleteId: id,
        });
    };
    let allMissions = (
        <div className={classes.Loading}>
            <CircularProgress style={{ color: "#5cf0f5" }} color="inherit" />
        </div>
    );
    if (!props.result.resultFetchLoading) {
        allMissions = props.result.result.map((result) => {
            const date = result.createdAt.split("T");
            const time = date[1].split(":");
            let RAN = "MOBILE BROADBAND";
            if (result.types === "LL") {
                RAN = "LOW LATENCY";
            }
            if (result.types === "ULL") {
                RAN = "ULTRA LOW LATENCY";
            }
            if (result.types === "A") {
                RAN = "AUTOMATIC";
            }
            return (
                <div key={result._id} style={resultStyles} className={classes.Result}>
                    <div className={classes.Description}>
                        <div className={classes.Des}>
                            <h3>Date : {date[0]}</h3>
                            <h3>
                                Time : {time[0]}:{time[1]}
                            </h3>
                            <h3>RAN Architecture : {RAN}</h3>
                            {result.area ? <h3>Estimated Area : {result.area.toFixed(2)}</h3> : null}
                        </div>
                        <div className={classes.Control}>
                            <Button
                                style={{ backgroundColor: "black", color: "white" }}
                                onClick={(id) => deleteMission(result._id)}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                    <div className={classes.Map}>
                        <GeneralMap region={result.location} regionLat={result.location[1]} regionLong={result.location[0]} />
                    </div>
                </div>
            );
        });
    }
    if (state.deleteLoading) {
        allMissions = (
            <div className={classes.Loading}>
                <CircularProgress style={{ color: "#5cf0f5" }} color="inherit" />
            </div>
        );
    }
    return <div className={classes.Missions}>{allMissions}</div>;
};

const mapStateToProps = (state) => {
    return {
        result: state.result,
        allElements: state.result.result.element,
        token: state.auth.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchResults: () => dispatch(action.resultFetch()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Missions));
