import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import { compose, combineReducers, applyMiddleware, createStore } from "redux";
import authReducer from "./store/reducer/auth/auth";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { isMobile } from "react-device-detect";
import deployReducer from "./store/reducer/deploy";
import resultReducer from './store/reducer/result'

let composeEnhancers = compose;
if (!isMobile) {
    composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
}
// process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null ||
const rootReducer = combineReducers({
    auth: authReducer,
    deploy: deployReducer,
    result:resultReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
