import React, { useRef, useState,useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import SideDrawer from "../../components/Toolbar/SideDrawer/SideDrawer";
import Toolbar from "../../components/Toolbar/Toolbar";
import classes from "./Layout.module.css";

const Layout = (props) => {
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const refOpenSideDrawer = useRef(false);
    useEffect(() => {
        setOpenSideDrawer(false)
    }, [])
    const drawerToggle = () => {
        refOpenSideDrawer.current = !refOpenSideDrawer.current;
        setOpenSideDrawer(refOpenSideDrawer.current);
    };
    return (
        <div className={classes.Layout}>
            <div className={classes.Header}>
                <SideDrawer drawerToggle={drawerToggle} openSideDrawer={openSideDrawer} />
                <Toolbar drawerToggle={drawerToggle} />
            </div>
            <main className={openSideDrawer ? classes.Main : null}>{props.children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
