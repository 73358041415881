import * as actionTypes from "../actionTypes";
import Axios from "axios";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (token, userDetails) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        user: userDetails,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const auth = (email, password) => {
    return (dispatch) => {
        dispatch(authStart());
        const body = {
            email: email,
            password: password,
        };
        let url = "https://5g.uavswarm.tech/api/create-session2";
        Axios.post(url, body)
            .then((response) => {
                if (!response.data.token) {
                    dispatch(authFail("Auth Failed"));
                }
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("userId", response.data.user.id);
                localStorage.setItem("userName", response.data.user.name);
                localStorage.setItem("userEmail", response.data.user.email);

                dispatch(authSuccess(response.data.token, response.data.user));
            })
            .catch((err) => {
                dispatch(authFail(err.response.data.msg));
            });
    };
};

export const authSignUp = (email, password, name) => {
    return (dispatch) => {
        dispatch(authStart());
        const body = {
            name: name,
            email: email,
            password: password,
        };
        let url = "https://5g.uavswarm.tech/api/new-user/";
        Axios.post(url, body)
            .then((response) => {
                if (!response.data.token) {
                    dispatch(authFail("Auth Failed"));
                }
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("userId", response.data.user.id);
                localStorage.setItem("userName", response.data.user.name);
                localStorage.setItem("userEmail", response.data.user.email);
                dispatch(authSuccess(response.data.token, response.data.user));
            })
            .catch((err) => {
                dispatch(authFail(err.response.data.msg));
            });
    };
};

export const authCheckState = () => {
    return (dispatch) => {
        const token = localStorage.getItem("token");
        const id = localStorage.getItem("userId");
        const name = localStorage.getItem("userName");
        const email = localStorage.getItem("userEmail");
        let user = {
            id,
            name,
            email,
        };
        if (!token) {
            dispatch(logout());
        } else {
            dispatch(authSuccess(token, user));
        }
    };
};
