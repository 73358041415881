//AUTH 
export const AUTH_START = "AUTH_START";
export const AUTH_SIGNUP_START = "AUTH_SIGNUP_START";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SIGNUP_FAIL = "SIGNUP_FAIL";


//FORM SUBMISSION TO DEPLOY DRONE

export const DEPLOY_FORM_SUBMISSION_START = "DEPLOY_FORM_SUBMISSION_START";
export const DEPLOY_FORM_SUBMISSION_SUCCESS = "DEPLOY_FORM_SUBMISSION_SUCCESS";
export const DEPLOY_FORM_SUBMISSION_FAIL = "DEPLOY_FORM_SUBMISSION_FAIL";


//RESULT FETCH 

export const RESULT_FETCH_START = "RESULT_FETCH_START";
export const RESULT_FETCH_SUCCESS = "RESULT_FETCH_SUCCESS";
export const RESULT_FETCH_FAIL = "RESULT_FETCH_FAIL";


// export const SET_NAVIGATION = 'SET_NAVIGATION';

// export const EVENT_FETCH_START = "EVENT_FETCH_START";
// export const EVENT_FETCH_SUCCESS = "EVENT_FETCH_SUCCESS";
// export const EVENT_FETCH_FAIL = "EVENT_FETCH_FAIL";



// export const EVENT_ENROLL_START = "EVENT_ENROLL_START";
// export const EVENT_ENROLL_SUCCESS = "EVENT_ENROLL_SUCCESS";
// export const EVENT_ENROLL_FAIL = "EVENT_ENROLL_FAIL";


// export const FETCH_ENROL_EVENT_START = "FETCH_ENROL_EVENT_START";
// export const FETCH_ENROL_EVENT_SUCCESS = "FETCH_ENROL_EVENT_SUCCESS";
// export const FETCH_ENROL_EVENT_FAIL = "FETCH_ENROL_EVENT_FAIL";


// export const CHECK_ENROLLMENT_START = "CHECK_ENROLLMENT_START";
// export const CHECK_ENROLLMENT_SUCCESS = "CHECK_ENROLLMENT_SUCCESS";
// export const CHECK_ENROLLMENT_FAIL = "CHECK_ENROLLMENT_FAIL";




// export const ENROLLED_EVENT_FETCH_START = "ENROLLED_EVENT_FETCH_START";
// export const ENROLLED_EVENT_FETCH_SUCCESS = "ENROLLED_EVENT_FETCH_SUCCESS";
// export const ENROLLED_EVENT_FETCH_FAIL = "ENROLLED_EVENT_FETCH_FAIL";


// export const FETCH_USER_EVENT_START = "FETCH_USER_EVENT_START";
// export const FETCH_USER_EVENT_SUCCESS = "FETCH_USER_EVENT_SUCCESS";
// export const FETCH_USER_EVENT_FAIL = "FETCH_USER_EVENT_FAIL";



