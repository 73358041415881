import React, { useState, useEffect } from "react";
import { FormHelperText, Button, CircularProgress, Radio } from "@material-ui/core";
import LeafletMap from "../../components/LeafletMap/LeafletMap";
import classes from "./DeployDrone.module.css";
import * as action from "../../store/actions/index";
import * as geolib from "geolib";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function DeployDrone(props) {
    const [state, setState] = useState({
        error: null,
        markers: [],
        disableMapError: null,
        formSubmissionAttempt: false,
        formValid: false,
        cost: 0,
        area: 0,
        showResult: false,
        showAddedDialogueBox: false,
        mode: null,
        modeSubmitted: false,
        regionLat: 28.6096577,
        regionLong: 77.040291,
    });
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        getGeoInfo();
    }, []);

    useEffect(() => {
        if (state.showAddedDialogueBox) {
            setTimeout(() => {
                setState({ ...state, showAddedDialogueBox: false });
            }, 1700);
        }
    }, [state.showAddedDialogueBox]);

    useEffect(() => {
        if (props.formSubmitted) {
            setState({
                ...state,
                showResult: false,
                showAddedDialogueBox: true,
            });
        }
    }, [props.formSubmitted]);

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setState({
                    ...state,
                    regionLat: data.latitude,
                    regionLong: data.longitude,
                });
            })
            .catch((error) => {
                console.log("Cannot get the location");
            });
    };

    const onSubmitHandler = (markers, error, disableMapError) => {
        if (state.incError) {
            setState({
                ...state,
                markers: markers,
                error: error,
                formValid: false,
                formSubmissionAttempt: true,
                disableMapError: disableMapError,
            });
        } else {
            let cost = 0;
            let area = 0;

            if (markers.length > 2) {
                area = 0;
                for (let i = 0; i < markers.length; i++) {
                    markers[i].reverse();
                }
                area = geolib.getAreaOfPolygon(markers);
                area = geolib.convertArea(area, "ha");
                cost = area * 500;
            }
            let formValid = false;
            if (!error && markers.length > 2) {
                formValid = true;
            }
            setState({
                ...state,
                markers: markers,
                error: error,
                formValid: formValid,
                formSubmissionAttempt: true,
                cost: cost,
                area: area,
                disableMapError: disableMapError,
            });
        }
    };

    const submitFormHandler = () => {
        if (state.formValid) {
            setState({
                ...state,
                showResult: true,
            });
            return;
        }
    };

    const handleClose = () => {
        setState({
            ...state,
            showResult: false,
        });
    };
    const agreeAndContinueHandler = () => {
        if (!state.formValid && !state.error) {
            return;
        } else {
            props.formSubmission(state.markers, state.inc, state.mode, state.area);
        }
    };

    const modeChangeHandler = (e) => {
        setState({
            ...state,
            mode: e,
        });
    };

    const modeSubmitHandler = () => {
        setState({
            ...state,
            modeSubmitted: true,
        });
    };

    let agreeAndContinue = (
        <div>
            <Dialog
                open={state.showResult}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ paddingBottom: "0.5rem" }} id="alert-dialog-slide-title">
                    Result
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Estimated Cost Rs.{state.cost.toFixed(3)}
                        <br />
                        <br />
                        Are you sure you want to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Retry
                    </Button>
                    {props.formSubmissionLoading ? (
                        <CircularProgress style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }} />
                    ) : (
                        <Button onClick={agreeAndContinueHandler} color="primary">
                            Agree
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );

    let dialogueBox = (
        <div
            className={classes.Modal}
            style={{
                transform: state.showAddedDialogueBox ? "translateY(0)" : "translateY(10vh)",
                opacity: state.showAddedDialogueBox ? "1" : "1",
            }}
        >
            <h1> Queued Successfully !!! </h1>
        </div>
    );
    let modeForm = (
        <div className={classes.DeployDrone}>
            <div className={classes.Selection}>
                <h1>Please select RAN Architecture Mode</h1>
                <div className={classes.Card}>
                    <Button id="MB" onClick={() => modeChangeHandler("MB")} style={{ width: "100%" }}>
                        <div className={classes.CardDescription}>
                            <h3>Mobile Broadband </h3>
                            <h2>&lt;10 ms latency</h2>
                        </div>
                        <div className={classes.CardRadio}>
                            <Radio
                                checked={state.mode === "MB"}
                                value="MB"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                                color="default"
                                style={{ color: "white" }}
                            />
                        </div>
                    </Button>
                </div>
                <div className={classes.Card}>
                    <Button id="LL" onClick={() => modeChangeHandler("LL")} style={{ width: "100%" }}>
                        <div className={classes.CardDescription}>
                            <h3>Low Latency </h3>
                            <h2>&lt;5ms latency eg. for automotive guided vehicles</h2>
                        </div>
                        <div className={classes.CardRadio}>
                            <Radio
                                checked={state.mode === "LL"}
                                value="LL"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                                color="default"
                                style={{ color: "white" }}
                            />
                        </div>
                    </Button>
                </div>
                <div className={classes.Card}>
                    <Button id="ULL" onClick={() => modeChangeHandler("ULL")} style={{ width: "100%" }}>
                        <div className={classes.CardDescription}>
                            <h3>Ultra Low Latency</h3>
                            <h2>1ms latency eg. For industrial automation</h2>
                        </div>
                        <div className={classes.CardRadio}>
                            <Radio
                                checked={state.mode === "ULL"}
                                value="ULL"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                                color="default"
                                style={{ color: "white" }}
                            />
                        </div>
                    </Button>
                </div>
                <div className={classes.Card}>
                    <Button id="A" onClick={() => modeChangeHandler("A")} style={{ width: "100%" }}>
                        <div className={classes.CardDescription}>
                            <h3>Automatic</h3>
                            <h2>Using ML to determine appropriate capacity distribution</h2>
                        </div>
                        <div className={classes.CardRadio}>
                            <Radio
                                checked={state.mode === "A"}
                                value="A"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                                color="default"
                                style={{ color: "white" }}
                            />
                        </div>
                    </Button>
                </div>
                <Button
                    style={{ marginTop: "1.5rem", width: "100%", fontWeight: "bold" }}
                    onClick={modeSubmitHandler}
                    variant="contained"
                    disabled={!state.mode}
                >
                    Next
                </Button>
            </div>
        </div>
    );
    let form = (
        <div className={classes.DeployDrone}>
            <div className={classes.Form}>
                {agreeAndContinue}
                <div className={classes.LeafletMap}>
                    <LeafletMap
                        onSubmitHandler={(markers, error, disableMapError) => onSubmitHandler(markers, error, disableMapError)}
                        submitFormHandler={submitFormHandler}
                        regionLat={state.regionLat}
                        regionLong={state.regionLong}
                    />
                </div>
                <FormHelperText className={classes.ErrorText} style={{ color: "red", fontSize: "15px", fontWeight: "500" }}>
                    {state.formSubmissionAttempt ? state.incError || state.error || state.disableMapError : null}
                </FormHelperText>
            </div>
            {dialogueBox}
        </div>
    );
    return state.modeSubmitted ? form : modeForm;
}

const mapStateToProps = (state) => {
    return {
        formSubmissionLoading: state.deploy.formSubmissionLoading,
        formSubmitted: state.deploy.formSubmitted,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        formSubmission: (markers, inc, mode, area) => dispatch(action.formSubmission(markers, inc, mode, area)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployDrone);
