import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utility";

const initialState = {
    token: null,
    user: {},
    isAuthenticated: false,
    signUpError: null,
    signUpLoading: false,
};

const authStart = (state, action) => {
    return updateObject(state, { signUpError: null, signUpLoading: true });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        user: action.user,
        token: action.idToken,
        signUpError: null,
        signUpLoading: false,
        isAuthenticated: true,
    });
};



const authFail = (state, action) => {
    return updateObject(state, {
        signUpError: action.error,
        signUpLoading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null,
        isAuthenticated: false,
        signUpLoading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;
