import React, { useState,useEffect } from "react";
import classes from "./SignUp.module.css";
import { Button, TextField, FormHelperText, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import validator from "validator";
import * as action from "../../store/actions/index";
import { connect } from "react-redux";

const styles = (theme) => ({
    cssLabel: {
        color: "white !important",
    },

    cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
            borderColor: `#5cf0f5`,
        },
        color: "white !important",
    },
    cssFocused: {
        color: "white !important",
    },

    notchedOutline: {
        borderWidth: "2px",
        borderColor: "#5cf0f5 !important",
    },
    notchedErrorOutline: {
        borderWidth: "2px",
        borderColor: "red",
    },
});

function SignUp(props) {
    const [state, setState] = useState({
        email: "",
        password: "",
        name: "",
        confirmPassword: "",
        emailError: "Email not provided.",
        passwordError: "Password not provided. ",
        confirmPasswordError: "Password doesn't match.",
        nameError: "Name not Provided",
        formSubmissionAttempt: false,
        formValid: false,
    });
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    useEffect(() => {
        if(state.formValid){
            props.onAuth(state.email,state.password,state.name)
        }
    }, [state.formValid])
    useEffect(() => {
        if (props.signUpError) {
            setState({
                ...state,
                formValid: false,
            });
        }
    }, [props.signUpError]);
    const checkValidation = (id, value) => {
        if (id === "name") {
            if (value.length >= 3) {
                return "";
            } else {
                return "Name not provided";
            }
        }
        if (id === "email") {
            if (!value) {
                return "Email not provided";
            }
            if (validator.isEmail(value)) {
                return "";
            } else {
                return "Email not valid";
            }
        }

        if (id === "password") {
            if (value.length >= 7) {
                return "";
            } else {
                return "Password length should be greater than 7";
            }
        }
        if (id === "confirmPassword") {
            if (state.password === value) {
                return "";
            } else {
                return "Password doesn't match";
            }
        }
    };

    const onInputChange = (evt) => {
        const value = evt.target.value.trim("");
        let errorId = `${evt.target.id}Error`;
        let error = checkValidation(evt.target.id, value);
        setState({
            ...state,
            [evt.target.id]: value,
            [errorId]: error,
        });
    };
    const submitHandler = () => {
        if (state.emailError || state.passwordError || state.confirmPasswordError) {
            setState({
                ...state,
                formValid: false,
                formSubmissionAttempt: true,
            });
        } else {
            setState({
                ...state,
                formValid: true,
                formSubmissionAttempt: true,
            });
        }
        
    };
    const cssClasses = props.classes;
    return (
        <div className={classes.SignUp}>
            <form noValidate autoComplete="off">
                <TextField
                    error={state.formSubmissionAttempt && state.nameError ? true : false}
                    id="name"
                    label="Name"
                    margin="normal"
                    variant="outlined"
                    className={classes.InputField}
                    InputLabelProps={{
                        classes: {
                            root: cssClasses.cssLabel,
                            focused: cssClasses.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: cssClasses.cssOutlinedInput,
                            focused: cssClasses.cssFocused,
                            notchedOutline: state.formSubmissionAttempt && state.nameError ?  cssClasses.notchedErrorOutline : cssClasses.notchedOutline ,
                        },
                        inputMode: "numeric",
                    }}
                    onChange={onInputChange}
                    value={state.name}
                />
                <TextField
                    error={state.formSubmissionAttempt && state.emailError ? true : false}
                    id="email"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    className={classes.InputField}
                    InputLabelProps={{
                        classes: {
                            root: cssClasses.cssLabel,
                            focused: cssClasses.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: cssClasses.cssOutlinedInput,
                            focused: cssClasses.cssFocused,
                            notchedOutline: state.formSubmissionAttempt && state.emailError ?  cssClasses.notchedErrorOutline : cssClasses.notchedOutline ,
                        },
                        inputMode: "numeric",
                    }}
                    onChange={onInputChange}
                    value={state.email}
                />
                <TextField
                    error={state.formSubmissionAttempt && state.passwordError ? true : false}
                    id="password"
                    label="Password"
                    margin="normal"
                    variant="outlined"
                    className={classes.InputField}
                    InputLabelProps={{
                        classes: {
                            root: cssClasses.cssLabel,
                            focused: cssClasses.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: cssClasses.cssOutlinedInput,
                            focused: cssClasses.cssFocused,
                            notchedOutline: state.formSubmissionAttempt && state.passwordError ?  cssClasses.notchedErrorOutline : cssClasses.notchedOutline ,
                        },
                        inputMode: "password",
                    }}
                    type="password"
                    onChange={onInputChange}
                    value={state.password}
                />
                <TextField
                    error={state.formSubmissionAttempt && state.confirmPasswordError ? true : false}
                    id="confirmPassword"
                    label="Confirm Password"
                    margin="normal"
                    variant="outlined"
                    className={classes.InputField}
                    InputLabelProps={{
                        classes: {
                            root: cssClasses.cssLabel,
                            focused: cssClasses.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: cssClasses.cssOutlinedInput,
                            focused: cssClasses.cssFocused,
                            notchedOutline: state.formSubmissionAttempt && state.confirmPasswordError ?  cssClasses.notchedErrorOutline : cssClasses.notchedOutline ,
                        },
                        inputMode: "password",
                    }}
                    type="password"
                    style={{ marginBottom: "1.5rem" }}
                    onChange={onInputChange}
                    value={state.confirmPassword}
                />
                <FormHelperText className={classes.ErrorText} style={{ color: "red", fontSize: "15px", fontWeight: "500" }}>
                    {state.formSubmissionAttempt
                        ? state.nameError || state.emailError || state.passwordError || state.confirmPasswordError || props.signUpError
                        : null}
                </FormHelperText>
                {props.authLoading ? (
                    <LinearProgress style={{height:"0.1rem",backgroundColor:"#5cf0f5" }} />
                ) : (
                    <Button variant="contained" style={{ fontWeight: "bold", marginTop: "1.5rem" }} onClick={submitHandler}>
                        Sign Up
                    </Button>
                )}
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        authLoading: state.auth.signUpLoading,
        signUpError:state.auth.signUpError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, name) => dispatch(action.authSignUp(email, password, name)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUp));
