import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Logo/Logo";
import classes from "./Footer.module.css";
import { IconContext } from "react-icons/lib";
import { AiOutlineCopyright } from "react-icons/ai";
import { ImHeart} from "react-icons/im";

const Footer = (props) => {
    return (
        <div className={classes.Footer}>
            <div className={classes.MainFooter}>
                <div className={classes.Logo}>
                    <NavLink to="/">
                        <Logo style={{borderRadius:"10px"}} />
                    </NavLink>
                </div>
                <div className={classes.Link}>
                    <ul>
                        <li>
                            <NavLink exact activeClassName={classes.active} to="/">
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName={classes.active} to="/">
                                Contact Us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName={classes.active} to="/">
                                Terms & Conditions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName={classes.active} to="/">
                                Privacy Policy
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.CopyRight}>
                <span>
                    <IconContext.Provider value={{ color: "white", size: "1.5em", style: { verticalAlign: "middle" } }}>
                        <AiOutlineCopyright />
                    </IconContext.Provider>
                    <p>Tech Swarm</p>
                </span>

                <span>
                    <p>Made with</p>
                    <IconContext.Provider value={{ color: "#BF1736", size: "1.5em", style: { verticalAlign: "middle" } }}>
                        <ImHeart />
                    </IconContext.Provider>
                    <p>in भारत</p>
                </span>
            </div>
        </div>
    );
};

export default Footer;
