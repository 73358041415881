import * as actionTypes from "./actionTypes";
import Axios from "axios";

export const formSubmissionStart = () => {
    return {
        type: actionTypes.DEPLOY_FORM_SUBMISSION_START,
    };
};

export const formSubmissionSuccess = () => {
    return {
        type: actionTypes.DEPLOY_FORM_SUBMISSION_SUCCESS,
    };
};

export const formSubmissionFail = (error) => {
    return {
        type: actionTypes.DEPLOY_FORM_SUBMISSION_FAIL,
        error: error,
    };
};

export const formSubmission = (markers, inc,mode,area) => {
    return (dispatch) => {
        dispatch(formSubmissionStart());
        const token = localStorage.getItem("token");
        const body = {
            points: markers,
            inc: inc,
            types:mode,
            area:area
        };
        let url = "https://5g.uavswarm.tech/api/deploy-drone";
        
        Axios.post(url, body, {
            headers: {
                "x-auth-header": token,
            },
        })
            .then((response) => {
                dispatch(formSubmissionSuccess());
            })
            .catch((err) => {
                dispatch(formSubmissionFail(err.response));
            });
    };
};
