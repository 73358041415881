import React from "react";
import classes from "./Prototype.module.css";
import mediaPath from "../../assets/video/prototype video.mp4";
import ReactPlayer from "react-player";

const Prototype = (props) => {
    return (
        <div className={classes.Prototype}>
            <div className={classes.PlayerContainer}>
                <ReactPlayer url={mediaPath} width="100%" height="100%" controls={true} />
            </div>
        </div>
    );
};

export default Prototype;
