import React, { Component } from "react";
import { Map, Marker, TileLayer, Polygon } from "react-leaflet";
import "./Leaflet.css";
import classes from "./GeneralMap.module.css";

class GeneralMap extends Component {
    state = {
        markers: [],
        disableMapClick: false,
        disableMapError: null,
        error: null,
        zoomLevel: 25,
    };
    zoomHandler = (e) => {
        console.log(e)
        return;

    };

    render() {
        // console.log(this.props.region)
        return (
            <div className={classes.MapForm}>
                <Map
                    style={{ zIndex: 1 }}
                    className={classes.Map}
                    center={[this.props.region[1], this.props.region[0]]}
                    attributionControl = {false}
                    zoomControl = {false}
                    dragging = {false}
                    zoom={13}
                    doubleClickZoom = {false}
                    closePopupOnClick =  {false} 
                    zoomSnap= {false} 
                    zoomDelta =  {false} 
                    trackResize= {false}
                    touchZoom= {false}
                    scrollWheelZoom = {false} 
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                        <Marker key={`marker-${this.props.region[1]}`} position={this.props.region.reverse()}>
                        </Marker>
                </Map>
            </div>
        );
    }
}

export default GeneralMap;


// {this.props.markers.map((position, idx) => (
//     <Marker key={`marker-${idx}`} position={position}>
//     </Marker>
// ))}