import React from "react";
import { Button, Dialog } from "@material-ui/core";
import classes from "./SideDrawer.module.css";
import Logo from "../../Logo/Logo";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide unmountOnExit direction="right" ref={ref} {...props} />;
});
const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.openSideDrawer) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    const goToHome = () => {
        props.drawerToggle();
        props.history.push("/");
    };

    let routes = (
        <nav>
            <Button>
                <NavLink activeClassName={classes.active} exact to={"/"}>
                    Home
                </NavLink>
            </Button>
            <Button>
                <NavLink activeClassName={classes.active} exact to={"/prototype"}>
                    Prototype
                </NavLink>
            </Button>
            <Button>
                <NavLink activeClassName={classes.active} exact to={"/signup"}>
                    Sign Up
                </NavLink>
            </Button>
            <Button>
                <NavLink activeClassName={classes.active} exact to={"/login"}>
                    Login In
                </NavLink>
            </Button>
        </nav>
    );

    if (props.isAuthenticated) {
        routes = (
            <nav>
                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/"}>
                        Home
                    </NavLink>
                </Button>
                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/deploy"}>
                        Deploy
                    </NavLink>
                </Button>

                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/missions"}>
                        Missions
                    </NavLink>
                </Button>
                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/result"}>
                        Result
                    </NavLink>
                </Button>
                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/prototype"}>
                        Prototype
                    </NavLink>
                </Button>

                <Button>
                    <NavLink activeClassName={classes.active} exact to={"/logout"}>
                        Logout
                    </NavLink>
                </Button>
            </nav>
        );
    }

    return (
        <div className={classes.Content}>
            <Dialog TransitionComponent={Transition} keepMounted open={props.openSideDrawer} onClick={props.drawerToggle}>
                <div className={attachedClasses.join(" ")}>
                    <div className={classes.LogoContainer}>
                        <Logo onClick={goToHome} style={{ borderRadius: "10px" }} />
                    </div>
                    {routes}
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};

export default connect(mapStateToProps, null)(withRouter(SideDrawer));
