import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import classes from "./Result.module.css";
import * as action from "../../store/actions/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router";

const Result = (props) => {
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    useEffect(() => {
        props.fetchResult();
    }, []);
    const submitHandler = ()=>{
        props.history.push("/deploy")
    }
    const resultStyles = {
        backgroundColor: "rgba(255, 255, 255, 0.46)",
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.25)",
    };
    let content = (
        <div className={classes.Results}>
            <Button style={resultStyles} className={classes.Result} onClick={submitHandler}>
                No Result Deploy To See Result
            </Button>
        </div>
    );
    if (props.result.resultFetchLoading) {
        content = (
            <div className={classes.Loading}>
                <div className={classes.backdrop}>
                    <CircularProgress style={{color:"#5cf0f5"}} color="inherit" />
                </div>
            </div>
        );
    }
    return content;
};

const mapStateToProps = (state) => {
    return {
        result: state.result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchResult: () => dispatch(action.resultFetch()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Result));
