import React, { Component } from "react";
import { Map, Marker, TileLayer, Polygon } from "react-leaflet";
import "./Leaflet.css";
import classes from "./Leaflet.module.css";
import { Button } from "@material-ui/core";
// import { MdWarning } from "react-icons/md";
// import { IconContext } from "react-icons/lib";

class LeafletMap extends Component {
    state = {
        markers: [],
        showBackDrop: false,
        submitted: false,
        disableMapClick: false,
        disableMapError: null,
        error: null,
        zoomLevel: 25,
    };

    addMarker = (e) => {
        if (this.state.disableMapClick) {
            this.setState({ disableMapError: "Click reset to edit. " }, () => {
                this.props.onSubmitHandler(this.state.markers, this.state.error,this.state.disableMapError);
            });
            return;
        }

        const newMarkers = this.state.markers;
        newMarkers.push([e.latlng.lat, e.latlng.lng]);
        this.setState({ markers: newMarkers }, () => {
            return;
        });
    };

    removeMarker = (e) => {
        if (this.state.disableMapClick) {
            return;
        }

        let newMarkers = this.state.markers;
        newMarkers = newMarkers.filter((marker) => marker[0] !== e.latlng.lat && marker[1] !== e.latlng.lng);
        this.setState({ markers: newMarkers });
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.markers.length < 3) {
            this.setState({ error: "Please select 3 or more points" }, () => {
                this.props.onSubmitHandler([], this.state.error,this.state.disableMapError);
                return;
            });
        } else {
            this.setState({ submitted: true, disableMapClick: true, error: null }, () => {
                this.props.onSubmitHandler(this.state.markers, this.state.error,this.state.disableMapError);
            });
        }
    };

    editHandler = (e) => {
        e.preventDefault();
        this.setState({ disableMapClick: false, submitted: false, markers: [], error: null,disableMapError:null }, () => {
            this.props.onSubmitHandler(this.state.markers, this.state.error,this.state.disableMapError);
        });
    };

    zoomHandler = (e) => {
        return;
    };

    render() {
        // let err = null;
        // if (this.state.error !== null) {
        //     err = (
        //         <div className={classes.ErrorBlock}>
        //             <span>
        //                 <IconContext.Provider value={{ color: "#5cf0f5", size: "1.4em", style: { verticalAlign: "middle" } }}>
        //                     <MdWarning />
        //                 </IconContext.Provider>
        //             </span>
        //             <span className={classes.ErrorTitle}>{this.state.error}</span>
        //         </div>
        //     );
        // }
        return (
            <div className={classes.MapForm}>
                <h3>Please select points in clockwise or in anti clockwise</h3>
                <Map
                    style={{ zIndex: 1 }}
                    className={classes.Map}
                    onZoom={this.zoomHandler}
                    center={[this.props.regionLat, this.props.regionLong]}
                    onClick={this.addMarker}
                    zoom={13}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {this.state.markers.map((position, idx) => (
                        <Marker key={`marker-${idx}`} onClick={this.removeMarker} position={position}>
                            {this.state.submitted ? <Polygon positions={this.state.markers} color="#00000080" /> : null}
                        </Marker>
                    ))}
                </Map>
                <div className={classes.AllControls}>
                    <div className={classes.Controls}>
                        <Button
                            style={{ backgroundColor: "black" }}
                            disabled={this.state.submitted}
                            variant="contained"
                            onClick={this.onSubmit}
                        >
                            Mark
                        </Button>
                        <Button
                            style={{ backgroundColor: "black" }}
                            variant="contained"
                            onClick={this.editHandler}
                            disabled={!this.state.submitted}
                        >
                            Reset
                        </Button>
                    </div>
                    <Button
                        disabled={!this.state.submitted}
                        style={{ backgroundColor: "black", marginRight: "0px" }}
                        variant="contained"
                        onClick={this.props.submitFormHandler}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    }
}

export default LeafletMap;

// <div className={classes.Controls}>
// <Button clicked={this.onSubmit}>
//     Submit
// </Button>
// <Button  clicked={this.editHandler} disabled={!this.state.submitted}>
//     Reset
// </Button>
// {err}
// </div>

// {this.state.markers.map((position, idx) => (
//     <Marker key={`marker-${idx}`} onClick={this.removeMarker} position={position}>
//         {this.state.submitted ? <Polygon positions={this.state.markers} color="#00000080" /> : null}
//     </Marker>
// ))}
