import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    formSubmissionLoading: false,
    formSubmitted: false,
    error: null,
};

const formSubmissionStart = (state, action) => {
    return updateObject(state, { formSubmissionLoading: true, formSubmitted: false });
};

const formSubmissionSuccess = (state, action) => {
    return updateObject(state, {
        formSubmissionLoading: false,
        formSubmitted: true,
        error: null,
    });
};

const formSubmissionFail = (state, action) => {
    return updateObject(state, {
        formSubmissionLoading: false,
        formSubmitted: false,
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEPLOY_FORM_SUBMISSION_START:
            return formSubmissionStart(state, action);
        case actionTypes.DEPLOY_FORM_SUBMISSION_SUCCESS:
            return formSubmissionSuccess(state, action);
        case actionTypes.DEPLOY_FORM_SUBMISSION_FAIL:
            return formSubmissionFail(state, action);
        default:
            return state;
    }
};

export default reducer;
